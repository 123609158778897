import './App.css';

function App() {
  return (
    <div className="App">
      <div>Vinyl Destination <br /> Philly</div>
      <div>Coming Soon</div>
    </div>
  );
}

export default App;
